<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>普通用户</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="关注日期">
                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="注册成功日期">
                <el-date-picker style="width: 96%" v-model="upDateSearchtime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="注册运动品类">
                <el-input style="width: 96%" v-model="search.motion_cate_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="注册目标品类">
                <el-input style="width: 96%" v-model="search.target_cate_name"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="已打品类标签">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
          <el-button size="medium" type="primary" @click="batchSetTags">
              批量设置标签
          </el-button>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button size="medium" type="primary" @click="exportList">
              导出
            </el-button>
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">
              显示搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table v-loading="loading" :data="tableData" border size="medium" @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column prop="user_uuid" label="用户uuid"> </el-table-column>
        <el-table-column prop="avatar_url" label="头像" width="80">
          <template slot-scope="scope">
            <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="昵称" min-width="150"> </el-table-column>
        <el-table-column prop="phone" label="手机号" width="200"> </el-table-column>
        <el-table-column prop="gender" label="性别" width="160">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.gender === 0" type="info">未知</el-tag>
            <el-tag size="mini" v-if="scope.row.gender === 1">男</el-tag>
            <el-tag size="mini" v-if="scope.row.gender === 2" type="danger">女</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="gender" label="注册运动品类" width="160">
          <template slot-scope="scope">
            <div style="display: inline-block;margin-left:4px;" v-for="(item, index) in scope.row.motion_category"
              :key="index">
              <el-tag v-if="item" size="mini" type="info">{{item.name}}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="gender" label="注册目标品类" width="160">
          <template slot-scope="scope">
            <div style="display: inline-block;margin-left:4px;" v-for="(item, index) in scope.row.target_category"
              :key="index">
              <el-tag v-if="item" size="mini" type="info">{{item.name}}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="是否注册成功">
          <template slot-scope="scope">
            <p style="margin:0;text-align:center">{{scope.row.phone ? '是' : '否'}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="注册成功时间" width="150">
          <template slot-scope="scope">
            <p style="margin:0;text-align:center">{{scope.row.phone ? scope.row.update_time : ""}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="关注时间" width="150">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="480">
          <template slot-scope="scope">
            <el-button v-if="is_auth('user.member.getinfo')" @click="goToDetail(scope.row)" size="mini">查看
            </el-button>
            <!-- <el-button
              type="primary"
              v-if="is_auth('user.member.givemoney') && scope.row.phone !== ''"
              @click="givemoney(scope.row)"
              size="mini"
              >赠送金额
            </el-button> -->
            <el-button type="primary" v-if="is_auth('user.member.givecoupon') && scope.row.phone !== ''"
              @click="givecoupon(scope.row)" size="mini">赠送优惠券
            </el-button>
            <el-button v-if="is_auth('user.member.optimizetsubscribe') && scope.row.phone !== ''" size="mini" type="primary"
              @click="thumbs_optimize(scope.row)">关注优化
            </el-button>
            <div style="display: inline-block; margin-left: 10px" v-if="is_auth('user.member.saverecommend') && scope.row.phone !== ''">
              <el-button v-if="scope.row.is_recommend === 1" size="mini" type="danger"
                @click="saverecommend(scope.row, 'cancle')">取消推荐
              </el-button>
              <el-button v-else size="mini" type="primary" @click="saverecommend(scope.row, 'save')">设为推荐
              </el-button>
            </div>
            <el-button size="mini" type="primary" style="display: inline-block; margin-left: 10px"
              @click="settagsHandle(scope.row)">设置标签
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
    </div>
    <el-dialog v-loading="loading" v-if="is_auth('store.coach.getcoach')" title="赠送优惠券" :visible.sync="givecoupon_page"
      width="70%" :close-on-press-escape="false" :close-on-click-modal="false">
      <el-row>
        <Coupon v-on:is_givecoupon="is_givecoupon" />
      </el-row>
    </el-dialog>
    <el-dialog v-loading="loading" v-if="is_auth('user.member.saverecommend')"
      :title="saveRecommendType === 'save' ? '确认推荐' : '取消推荐'" :visible.sync="saverecommendShow" width="30%"
      :close-on-press-escape="false" :close-on-click-modal="false">
      <span>是否确认将{{ user_name
      }}{{ saveRecommendType === "save" ? "设为" : "移出" }}推荐用户</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleSaverecommend">取 消</el-button>
        <el-button type="primary" @click="saveUserRecommend">确 定</el-button>
      </span>
    </el-dialog>

    <Settags ref="Settags" @refreshDataList="onRefreshDataList"/>
  </div>
</template>
<script>
import Coupon from "./page/Coupon";
import Settags from './Settags.vue';

export default {
  components: {
    Coupon,
    Settags
  },
  data() {
    return {
      page_name: "普通用户",
      issearch: false,
      searchtime: null, //时间
      upDateSearchtime:null, // 注册成功时间
      loading: true,
      search: {}, // 搜索条件
      tableData: [], // 列表内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      givecoupon_page: false, // 赠送优惠券页面
      saverecommendShow: false, // 设为推荐页面
      coupon_user_uuid: "", // 优惠券接收人id
      user_id: "", // 用户uuid
      user_name: "", // 当前选择的用户名
      saveRecommendType: "save", // 设置推荐的类型
      selections:[], // 当前选中的
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() { },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 赠送金额
    givemoney(info) {
      this.$prompt("请输入赠送金额￥", "赠送【" + info.user_nick + "】", {
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
        inputErrorMessage: "金额不正确",
      })
        .then(({ value }) => {
          this.is_givemoney(info.user_uuid, value);
        })
        .catch(() => { });
    },
    is_givemoney(user_uuid, money) {
      let postdata = {
        api_name: "user.member.givemoney",
        token: this.Tool.get_l_cache("token"),
        user_uuid,
        money,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表
            },
          });
        } else {
          this.$message.error(json.message);
        }
      });
    },
    // 赠送优惠券
    givecoupon(info) {
      this.coupon_user_uuid = "";
      this.givecoupon_page = true;
      // console.log('info', info)
      this.coupon_user_uuid = info.user_uuid;
    },
    is_givecoupon(coupon_uuid) {
      let coupon_user_uuid = this.coupon_user_uuid;
      // console.log('coupon_uuid', coupon_uuid)
      // console.log('coupon_user_uuid', coupon_user_uuid)

      let postdata = {
        api_name: "user.member.givecoupon",
        token: this.Tool.get_l_cache("token"),
        coupon_user_uuid,
        coupon_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "赠送成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.givecoupon_page = false;
              this.coupon_user_uuid = "";
              this.getlist(); // 刷新列表
            },
          });
        } else {
          this.$message.error(json.message);
        }
      });
    },
    // 初始化
    init() {
      this.searchtime = null;
      this.upDateSearchtime = null;
      this.search = {
        user_nick: "",
        phone: "",
        motion_cate_name:"",
        target_cate_name:"",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "user.member.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      if (this.upDateSearchtime !== null) {
        postdata.upstarttime = this.upDateSearchtime[0];
        postdata.upendtime = this.upDateSearchtime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
          this.selections = []
        } else {
          this.tableData = [];
          this.count = 0;
          this.$message.error(json.message);
        }
      });
    },
    goToDetail(item) {
      const { user_uuid } = item;
      this.$router.push({ path: "/user/member/info", query: { user_uuid: user_uuid } });
    },
    // 关注优化
    thumbs_optimize(item) {
      const { user_uuid } = item;
      this.$prompt("请输入需要优化的关注数", "关注优化", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "number",
      })
        .then(({ value }) => {
          this.optimizetsubscribe(user_uuid, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 关注优化请求接口
    optimizetsubscribe(quilt_user_uuid, quantity) {
      let postdata = {
        api_name: "user.member.optimizetsubscribe",
        token: this.Tool.get_l_cache("token"),
        quantity,
        quilt_user_uuid,
      };
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 0) {
          if (res.data.thumb_sum === 0) {
            this.$message({
              message: "优化失败，当前可用虚拟用户数为零",
              type: "error",
              duration: 2000,
            });
          } else {
            if (res.data.thumb_sum < quantity) {
              this.$message({
                message: `当前可用的虚拟用户数少于输入的优化数，已优化${res.data.thumb_sum}`,
                type: "warning",
                duration: 2500,
                onClose: () => {
                  this.getlist(); // 刷新列表(重新搜索)
                },
              });
            } else {
              this.$message({
                message: "优化成功",
                type: "success",
                duration: this.env.message_duration,
                onClose: () => {
                  this.getlist(); // 刷新列表(重新搜索)
                },
              });
            }
          }
        } else {
          this.Tool.errormes(res);
        }
      });
    },
    // 将用户设置为推荐用户
    saverecommend(item, type) {
      this.user_name = item.user_nick;
      this.user_id = item.user_uuid;
      this.saverecommendShow = true;
      this.saveRecommendType = type;
    },
    // 关闭设置推荐用户弹窗
    cancleSaverecommend() {
      this.user_name = "";
      this.user_id = "";
      this.saverecommendShow = false;
    },
    // 设置用户为推荐用户
    saveUserRecommend() {
      const { saveRecommendType } = this;
      let postdata = {
        api_name: "user.member.saverecommend",
        token: this.Tool.get_l_cache("token"),
        user_uuid: this.user_id,
        is_recommend: saveRecommendType === "cancle" ? 0 : 1,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 0) {
          this.cancleSaverecommend();
          this.getlist(); // 刷新列表
          this.$message({
            message: saveRecommendType === "cancle" ? "取消推荐成功" : "设置推荐成功",
            type: "success",
            duration: this.env.message_duration,
          });
        } else {
          this.Tool.errormes(res);
        }
      });
    },
    // 导出用户列表
    exportList() {
      let postdata = {
        api_name: "user.member.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        export: "yes"
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      if (this.upDateSearchtime !== null) {
        postdata.upstarttime = this.upDateSearchtime[0];
        postdata.upendtime = this.upDateSearchtime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 10005) {
          this.Tool.errormes(res);
        } else {
          location.href = res;
        }
      });
    },
    settagsHandle(item) {
      this.$refs.Settags?.show(item);
    },
    onRefreshDataList() {
      this.getlist()
    },
    handleSelectionChange(args) {
      this.selections = [...args]
      console.log(args,'handleSelectionChange')
    },
    batchSetTags() {
      if(this.selections.length) {
        this.$refs.Settags?.show(this.selections);
      } else {
        this.$message({
          message: "请至少勾选一个用户",
          type: 'warning'
        });
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}
</style>
