<template>
    <!--内容-->
    <div class="page-content-x">
        <!--搜索-->
        <div v-show="issearch" class="page-content-search">
            <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="优惠券名称">
                            <el-input v-model="search.coupon_title"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="page-content-search-button">
                    <el-col :span="24">
                        <el-form-item>
                            <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                            </el-button>
                            <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!--标题-->
        <el-row>
            <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            <el-col :span="12">
                <div class="top-operation-button">
                    <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                               @click="issearch = !issearch">隐藏搜索
                    </el-button>
                    <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                               @click="issearch = !issearch">显示搜索
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <div style="height: 20px;"></div>
        <!--列表-->
        <el-table
                size="medium"
                border
                v-loading="loading"
                :data="tableData"
                style="width: 100%">
            <el-table-column
                    prop="coupon_title"
                    label="优惠券名称">
            </el-table-column>
            <el-table-column
                    prop="cover"
                    label="优惠券封面"
                    width="100">
                <template slot-scope="scope">
                    <viewer v-if="!is_empty(scope.row.cover)">
                        <el-avatar shape="square" :size="22" :src="scope.row.cover"></el-avatar>
                    </viewer>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="state"
                    label="状态"
                    width="100">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.state===1">正常</el-tag>
                    <el-tag size="mini" v-if="scope.row.state===2" type="danger">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="range_type"
                    label="适用范围"
                    width="100">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.range_type===1">全平台</el-tag>
                    <el-tag size="mini" v-if="scope.row.range_type===2" type="success">
                        {{ scope.row.company_name }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="store_range"
                    label="门店范围"
                    width="100">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.store_range===1">所有门店</el-tag>
                    <el-tag size="mini" v-if="scope.row.store_range===2" type="success">指定门店</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lesson_range"
                    label="课程范围"
                    width="100">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.lesson_range===1">所有课程</el-tag>
                    <el-tag size="mini" v-if="scope.row.lesson_range===2" type="success">指定课程</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="full_money"
                    label="满减限制">
                <template slot-scope="scope">
                    <span>满￥{{ scope.row.full_money }}可用</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="coupon_money"
                    label="抵扣金额">
                <template slot-scope="scope">
                    <span>￥{{ scope.row.coupon_money }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="validity_time"
                    label="有效期">
                <template slot-scope="scope">
                    <span v-if="scope.row.validity_time===0">无限制</span>
                    <span v-else>{{ scope.row.validity_time }}天</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="use"
                    label="使用人次"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="create_time"
                    label="创建时间">
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="130">
                <template slot-scope="scope">
                    <!--                    <el-button
                                                v-if="is_auth('coupon.library.getinfo')"
                                                @click="tosee(scope.row.coupon_uuid)"
                                                size="mini">查看
                                        </el-button>-->
                    <el-button
                            v-if="is_auth('coupon.library.getinfo')"
                            type="primary"
                            @click="is_givecoupon(scope.row.coupon_uuid)"
                            size="mini">赠送
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="height: 20px;"></div>
        <!--分页-->
        <el-pagination
                @current-change="getlist"
                :page-size="this.env.pageSize"
                :pager-count="7"
                background
                layout="prev, pager, next, total"
                :current-page.sync="page"
                :total="count">
        </el-pagination>

    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '优惠券',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                coupon_title: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "coupon.library.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                state: 1,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 赠送
        is_givecoupon(coupon_uuid) {
            this.$emit('is_givecoupon', coupon_uuid)
        },
        // 查看
        tosee(coupon_uuid) {
            this.$router.push({path: '/coupon/library/info', query: {coupon_uuid}})
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
