<template>
  <div>
    <el-dialog
      title="设置标签"
      :visible.sync="editPage"
      width="60%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <!--内容-->
      <div class="settags_body">
        <el-row>
          <h3>已选用户</h3>
        </el-row>
        <el-row>
          <div
            class="settags_item"
            :class="{ settags_items: curInfos.length > 1 }"
          >
            <el-tag
              style="margin: 10px;"
              v-for="(item, index) in curInfos"
              :key="index"
              >{{ item.user_nick || "" }}</el-tag
            >
          </div>
        </el-row>
        <el-row>
          <h3>运动品类标签</h3>
        </el-row>
        <el-row>
          <div class="settags_item">
            <MotionCategorySelect
              :motionCategoryList.sync="motion_category"
              :max="10000"
            />
          </div>
        </el-row>
        <el-row>
          <h3>运动效果品类标签</h3>
        </el-row>
        <el-row>
          <div class="settags_item">
            <MotioneffectSelect
              :motionffectList.sync="target_category"
              :max="10000"
            />
          </div>
        </el-row>
        <el-row>
          <h3>心理需求品类标签</h3>
        </el-row>
        <el-row>
          <div class="settags_item">
            <el-checkbox-group v-model="needstagChecked">
              <el-checkbox
                class="category-item"
                v-for="item in needstagList"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-row>
      </div>
      <el-row style="margin: 20px 0px">
        <el-button size="medium" type="primary" @click="save">确 定</el-button>
        <el-button size="medium" @click="editPage = false">取 消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import MotionCategorySelect from "@/components/MotionCategorySelect";
import MotioneffectSelect from "@/components/MotioneffectSelect";
export default {
  components: {
    MotionCategorySelect,
    MotioneffectSelect,
  },
  data() {
    return {
      editPage: false,
      needstagList: [],
      needstagChecked: [],
      curInfos: [],
      motion_category: [],
      target_category: [],
    };
  },
  methods: {
    show(item) {
      console.log(item);
      this.editPage = true;
      this.curInfos = [];
      this.motion_category = [];
      this.target_category = [];
      this.needstagChecked = [];

      if (Array.isArray(item)) {
        this.curInfos = item;
      } else {
        this.curInfos = [item];

        this.motion_category = [...item.motion_category];
        this.target_category = [...item.target_category];
        this.needstagChecked =
          item.needs_category?.map((item) => item.id) || [];
      }

      //   this.motion_category = [{ id: 38, name: 111 }];
      //   this.target_category = [{ id: 86, name: 222 }];
      //   this.needstagChecked = [99];

      if (!this.needstagList.length) {
        this.getneedstagList();
      }
    },
    getneedstagList() {
      let postdata = {
        api_name: "category.needstag.getlist",
        token: this.Tool.get_l_cache("token"),
      };
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.Tool.post_data("oss", postdata, (json) => {
        loading.close();
        if (json.code === 0 && Array.isArray(json.data.list)) {
          this.needstagList = json.data.list;
        }
      });
    },
    save() {
      let postdata = {
        api_name: "user.member.settags",
        token: this.Tool.get_l_cache("token"),
        user_uuids: this.curInfos.map((item) => item.user_uuid),
        motion_category_ids: this.motion_category.map((item) => item.id),
        target_category_ids: this.target_category.map((item) => item.id),
        needs_category_ids: [...this.needstagChecked],
      };
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.Tool.post_data("oss", postdata, (json) => {
        loading.close();
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.editPage = false;
              this.$emit("refreshDataList");
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    setchecked() {},
  },
};
</script>
<style scoped>
>>> .el-dialog__body {
  padding: 10px 20px;
}
.page-content-search {
  margin-top: 10px;
}

.settags_body {
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.settags_item {
  padding: 0 10px;
}

.category-item {
  margin-bottom: 8px;
  min-width: 160px;
}

.settags_items {
  border: 1px solid #909399;
  border-radius: 5px;
}
</style>
